import React from 'react'
import DayTourCards from './DayTourCards'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Cebu Bohol Day Tours</title>
        <meta
          name='Day Tours'
          content='Got your hotel and bookings covered? Let us provide you with the best day use experiences, from snorkling and scuba diving in Panglao with the sea turtles, waterfalls, and more!'
        />
      </Helmet>
      <main role='main'>
        <div className='container'>
          <DayTourCards></DayTourCards>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Home
