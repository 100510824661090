import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tarsier from "../images/TARSIER.jpg";
import Magellan from "../images/MagellansCross.jpg";
import Flowers from "../images/Sirao-Flower-Garden.jpg";
import Atv from "../images/ATVRide.jpg";
import WhaleShark from "../images/WhaleShark.jpg";
import ChocolateHills from "../images/ChocolateHills.jpg";
import Card from "./Card";

const HomeCards = () => {
   const card1 = {
      title: "Whale Shark Viewing",
      image: WhaleShark,
      content: `Visit the beautiful quiet town of Oslob to have majestic close
    encounters with whale sharks! These sharks are famous all over the world
    as the gentle giants of the ocean, and they are only found in tropical places like Cebu!`,
   };
   const card2 = {
      title: "Chocolate Hills",
      image: ChocolateHills,
      content: `Gaze upon the mysterious Chocolate Hills of Bohol! Their design
  and shape fascinates, and the beauty of their brown summer
  color makes these a once in a life time experience to view from
  the Chocolate Hills Complex.`,
   };

   const card3 = {
      title: "Tarsier Conservatories",
      image: Tarsier,
      content: `Tarsiers are considered the world's smallest primate found in Bohol. They are territorial and fragile, and sensitive to noise and lights. The conservatories in Bohol 
      are created and maintained to give both you and the Tarsiers the best experience.`,
   };
   const card4 = {
      title: "Magellan's Cross",
      image: Magellan,
      content: `In the heart of Cebu City lies the original cross planted by Magellan during his arrival in Cebu on March 15, 1521. This is the beginning and birth of 
      Catholicism in the Philippines, now the country's dominant religion.`,
   };
   const card5 = {
      title: "Sirao Flower Gardens",
      image: Flowers,
      content: `Located in a mountain enclave not far from the heart of Cebu City are the Sirao Flower Gardens. Also known as Little Amsterdam,
      these gardens are always in bloom with Celosias and other flowers, making them a perfect Tourism Destination.`,
   };
   const card6 = {
      title: "Atv Ride",
      image: Atv,
      content: `Explore the Chocolate hills, and optionally get down and dirty in the rough terrain! The Atv trails feature streams, dense tropical off road paths,
      and breathtaking views from the top of the hill Bud Agta. Available in Single or Buggy-style for couples and families.`,
   };
   return (
      <Row
         xs={1}
         md={2}
         lg={3}
         className="g-4 setOfCards">
         <Col>
            <Card props={card1}></Card>
         </Col>
         <Col>
            <Card props={card2}></Card>
         </Col>
         <Col>
            <Card props={card3}></Card>
         </Col>
         <Col>
            <Card props={card4}></Card>
         </Col>
         <Col>
            <Card props={card5}></Card>
         </Col>
         <Col>
            <Card props={card6}></Card>
         </Col>
         <Col>
            <h1>Convenient</h1>
            <div className="large">
               Experience our hassle-free services, where you receive personal
               attention to make your trip-experience a delight from start to finish.
            </div>
         </Col>
         <Col>
            <h1>Affordable</h1>
            <div className="large">
               Quality Service, yet friendly on the wallet, is the best way to travel.
               We tailor our services to your specific budget, and our staff are
               looking for the best deal for you.
            </div>
         </Col>
         <Col>
            <h1>Comfortable</h1>
            <div className="large">
               Your safety is a top priority with Cebu Bohol Tours. The tours that we
               provide include our years of personal experience choosing to work with
               only reputable business owners, reliable transportation, and secure and
               enjoyable lodging facilities.
            </div>
         </Col>
         <Col>
            <h1>Price Reliability</h1>
            <div className="large">
               We do not provide exact prices on our website, as prices are subject to
               change. In order to guarantee the most up to date pricing, we quote you
               according to the most recent prices that we have access to.
            </div>
         </Col>
      </Row>
   );
};

export default React.memo(HomeCards);
