import React from "react";

import mainImage from "../images/TravelBackground.png";

const HomeCarousel = () => {
   return (
      <img
         className="d-block w-100"
         src={mainImage}
         alt="Explore"
      />
   );
};

export default HomeCarousel;
