import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DayTourModal from "./DayTourModal";

const DayTourCard = ({ props }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const toggleModal = () => {
      setIsModalOpen((prevState) => {
         return !prevState;
      });
   };

   return (
      <React.Fragment>
         <DayTourModal
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            details={props.details}
            image={props?.image}
            title={props.title}
         />
         <Card className="dayTourCard">
            <Card.Body>
               <Row>
                  <Col>
                     <Card.Title className="dayTourCardTitle">
                        {props.title}
                     </Card.Title>
                  </Col>
                  <Col>
                     <Button
                        className="dayTourCardButton"
                        variant="primary"
                        onClick={toggleModal}>
                        Details {props.image && " and Pictures!"}
                     </Button>
                  </Col>
               </Row>
            </Card.Body>
         </Card>
      </React.Fragment>
   );
};

export default React.memo(DayTourCard);
