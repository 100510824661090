import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo.jpg'

import debug from 'sabio-debug'

function SiteNav() {
  const _logger = debug.extend('SiteNav')
  _logger('navbar')

  return (
    <nav
      className='navbar navbar-expand-md navbar-dark bg-dark h4'
      aria-label='Fourth navbar example'
    >
      <div className='container'>
        <Link
          className='navbar-brand'
          to='/'
        >
          <img
            src={Logo}
            width='50'
            height='50'
            className='d-inline-block align-top'
            alt='Cebu Bohol Tours .com'
          />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarsExample04'
          aria-controls='navbarsExample04'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div
          className='collapse navbar-collapse'
          id='navbarsExample04'
        >
          <ul className='navbar-nav me-auto mb-2 mb-md-0'>
            <li className='nav-item'>
              <Link
                to='/'
                className='nav-link px-2 text-white link-button'
              >
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-link px-2 text-white link-button'
              >
                Contact Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/packagetours'
                className='nav-link px-2 text-white link-button'
              >
                Package Tours
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/daytours'
                className='nav-link px-2 text-white link-button'
              >
                Day Tours
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/customizedtours'
                className='nav-link px-2 text-white link-button'
              >
                Customized Tours
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default SiteNav
