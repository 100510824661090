import React, { useState } from "react";
import { Modal, ModalBody, Col, Button, Card } from "react-bootstrap";
import DetailedForm from "./DetailedForm";

const DayTourModal = (props) => {
   const [form, setForm] = useState(false);
   const closeModal = () => {
      props.toggleModal();
      setForm(false);
   };

   const toggleForm = () => {
      setForm(!form);
   };

   return (
      <>
         <Modal show={props.isModalOpen}>
            <ModalBody>
               {props.image && (
                  <Card.Img
                     variant="top"
                     src={props.image}
                  />
               )}
               <Col>
                  <div className="tourDetails">{props.details}</div>

                  <Button onClick={toggleForm}>Request a Quote</Button>
                  {form && <DetailedForm title={props.title}></DetailedForm>}
                  <Button
                     color="secondary"
                     className="float-right dripicons-cross btn btn-primary"
                     style={{ float: "right" }}
                     onClick={closeModal}></Button>
               </Col>
            </ModalBody>
         </Modal>
      </>
   );
};

export default DayTourModal;
