import React from "react";
import { Form, Input } from "semantic-ui-react";
import { Button } from "react-bootstrap";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "./contact.css";

const ContactUs = () => {
   const SERVICE_ID = "service_oivhbaf";
   const TEMPLATE_ID = "template_xc5n3fm";
   const USER_ID = "I6drwCwYHB5A3yHTa";

   const handleOnSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
         (result) => {
            console.log(result.text);
            Swal.fire({
               icon: "success",
               title: "Message Sent Successfully",
            });
         },
         (error) => {
            console.log(error.text);
            Swal.fire({
               icon: "error",
               title: "Ooops, something went wrong",
               text: error.text,
            });
         }
      );
      e.target.reset();
   };

   return (
      <div className="container">
         <section
            id="content"
            style={{ float: "center", width: "100%" }}>
            <Form onSubmit={handleOnSubmit}>
               <h1>Contact Us</h1>
               <Form.Field
                  control={Input}
                  name="user_email"
                  placeholder="Email…"
                  required
                  icon="mail"
                  iconPosition="left"
               />
               <Form.Field
                  control={Input}
                  name="user_name"
                  placeholder="Name…"
                  required
                  icon="user circle"
                  iconPosition="left"
               />
               <Form.Field
                  id="form-textarea-control-opinion"
                  as="textarea"
                  rows="8"
                  name="user_message"
                  placeholder="Message…"
                  required
               />
               <Button
                  type="submit"
                  className="btn btn-success">
                  Submit
               </Button>
            </Form>
         </section>
      </div>
   );
};

export default ContactUs;
