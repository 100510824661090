import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DayTourCard from "./DayTourCard";
import CebuCityTour from "../images/CebuCityTour.jpg";
import AndaTour from "../images/AndaTour.jpg";
import CebuUphill from "../images/CebuUphill.png";
import OslobKawasan from "../images/OslobKawasanTour.png";
import TwinCity from "../images/TwinCityTour.png";
import PanglaoLand from "../images/PanglaoTour.jpg";
import PanglaoIsland from "../images/PanglaoIsland.jpg";
import Bohol from "../images/Bohol.jpg";
import Oslob from "../images/Oslob.jpg";
import KawasanMoalboal from "../images/KawasanMoalboal.jpg";

const DayTourCards = () => {
   const header = "Tour Itinerary Highlights";
   const card1 = {
      title: "Cebu City Tour",
      image: CebuCityTour,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Sirao Flower Garden</li>
               <li>Temple of Leah</li>
               <li>French De la Vie</li>
               <li>Colon Street</li>
               <li>Sto. Niño Church</li>
               <li>Magellan's Cross</li>
               <li>Fort San Pedro</li>
               <li>Cebu Heritage Monument</li>
               <li>Yap San Diego Ancestral House</li>
               <li>Rico's Lechon or House of Lechon (Optional)</li>
            </ul>
         </>
      ),
   };
   const card2 = {
      title: "Cebu Uphill Tour",
      image: CebuUphill,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>West 35</li>
               <li>JVR Island in the Sky</li>
               <li>Adventure Cafe: Zipline, WallClimbing, Resto (Optional)</li>
               <li>Buwakan ni Alejandra</li>
               <li>Sirao Flower Garden</li>
               <li>Tops Look Out</li>
               <li>Temple of Leah</li>
               <li>Taoist Temple</li>
            </ul>
         </>
      ),
   };
   const card3 = {
      title: "Cebu Twin City Tour",
      image: TwinCity,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Sirao Flower Garden</li>
               <li>Temple of Leah</li>
               <li>Taoist Temple</li>
               <li>French De la Vie</li>
               <li>Colon Street</li>
               <li>Sto. Niño Church</li>
               <li>Magellan's Cross</li>
               <li>Fort San Pedro</li>
               <li>Cebu Heritage Monument</li>
               <li>Yap San Diego Ancestral House</li>
               <li>Lapu-Lapu Shrine</li>
               <li>Guitar Factory (Alegre)</li>
               <li>10k Roses</li>
            </ul>
         </>
      ),
   };
   const card4 = {
      title: "Cebu Oslob Tour",
      image: Oslob,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Swimming with the WhaleShark</li>
               <li>Sumilon Island White Sandbar (no Wednesdays)</li>
               <li>Tumalog Waterfalls</li>
               <li>Oslob Cuartel</li>
               <li>Oslob Ruins</li>
               <li>Oslob Church</li>
               <li>Simala Church</li>
               <li>Car-Car Pasalubong Shop</li>
               <li>Taboan Market</li>
            </ul>
         </>
      ),
   };
   const card5 = {
      title: "Cebu Oslob & Maolboal Tour",
      image: OslobKawasan,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Swimming with the WhaleShark</li>
               <li>Sumilon Island</li>
               <li>Pescador Island and Fish Sanctuary</li>
               <li>Turtle Watching</li>
               <li>Sardines Watching</li>
               <li>Milk Station (Optional)</li>
               <li>Car-Car Pasalubong Shop (Optional)</li>
               <li>Back to Hotel</li>
            </ul>
         </>
      ),
   };
   const card6 = {
      title: "Cebu Kawasan & Moalboal Tour",
      image: KawasanMoalboal,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Canyoneering (Optional)</li>
               <li>Kawasan Falls or Mantayupan Falls</li>
               <li>Piscador Island</li>
               <li>Fish Sanctuary</li>
               <li>Sardines Watching</li>
               <li>Turtle Watching</li>
               <li>Milk Station (Optional)</li>
               <li>Carcar Pasalubong Shop (Optional)</li>
            </ul>
         </>
      ),
   };
   const card7 = {
      title: "Bohol Countryside Tour",
      image: Bohol,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Chocolate Hills View Deck</li>
               <li>ATV Ride (Optional)</li>
               <li>Zipline (Optional)</li>
               <li>Man Made Forest</li>
               <li>Loboc River Cruise</li>
               <li>Tarsier Sanctuary</li>
               <li>Baclayon Church</li>
               <li>Baclayon Museum</li>
               <li>Python (You can hold it!)</li>
               <li>Blood Compact Site</li>
               <li>Baclayon Church</li>
               <li>Souvenir Shop</li>
               <li>Airport Seaport Hotel</li>
            </ul>
         </>
      ),
   };
   const card8 = {
      title: "Panglao Island Hopping Tour",
      image: PanglaoIsland,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Balicasag Island</li>
               <li>Fish Sanctuary</li>
               <li>Dolphin Watching (Optional)</li>
               <li>Virgin Island</li>
               <li>Back to Hotel</li>
            </ul>
         </>
      ),
   };
   const card9 = {
      title: "Panglao Land Tour",
      image: PanglaoLand,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Nova Shell Museum</li>
               <li>Panglao Church</li>
               <li>Dauis Church</li>
               <li>Hinagdanan Cave</li>
               <li>Bohol Bee Farm</li>
               <li>White Sand Beach (Optional)</li>
               <li>Mirror of the World (Optional)</li>
            </ul>
         </>
      ),
   };
   const card10 = {
      title: "Anda Tour",
      image: AndaTour,
      details: (
         <>
            <h1>{header}</h1>
            <ul>
               <li>Can Umantad Waterfalls</li>
               <li>Candijay Mini Rice Terraces</li>
               <li>Canawa Cold Spring</li>
               <li>Anda De Boracay / Quinale Beach</li>
               <li>Back to Hotel</li>
            </ul>
         </>
      ),
   };

   return (
      <Row
         xs={1}
         md={1}
         lg={1}
         className="g-4 setOfCards">
         <Col>
            <DayTourCard props={card1}></DayTourCard>
            <DayTourCard props={card2}></DayTourCard>
            <DayTourCard props={card3}></DayTourCard>
            <DayTourCard props={card4}></DayTourCard>
            <DayTourCard props={card5}></DayTourCard>
            <DayTourCard props={card6}></DayTourCard>
            <DayTourCard props={card7}></DayTourCard>
            <DayTourCard props={card8}></DayTourCard>
            <DayTourCard props={card9}></DayTourCard>
            <DayTourCard props={card10}></DayTourCard>
         </Col>
      </Row>
   );
};

export default React.memo(DayTourCards);
