import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DayTourCard from './DayTourCard'
import { Helmet } from 'react-helmet'

const PackageTours = () => {
  const header = 'Tour Itinerary Highlights'
  const card1 = {
    title: '2 Days 1 Night Cebu',
    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Jife jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Terminal fees</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 1 night</li>
            <li>▶️ 1 free lunch for canyoneering</li>
          </ul>
          <br />
          <h2>🌄 🌄1st Day MOALBOAL + KAWASAN FALLS</h2>
          <ul>
            <li>👉HOTEL PICK UP 4:00AM</li>
            <li>👉PESCADOR ISLAND</li>
            <li>👉SNORKELING AT FISH SANCTUARY</li>
            <li>👉SARDINES WATCHING</li>
            <li>👉TURTLE WATCHING</li>
            <li>👉CANYONEERING (OPTIONAL) WITH MEAL/LUNCH</li>
            <li>👉KAWASAN WATERFALLS</li>
            <li>👉SUNSET VIEW (LAMBOG)</li>
            <li>👉HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 10-12hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day OSLOB</h2>
          <ul>
            <li>👉PICK UP 4:30AM</li>
            <li>👉SUNRISE VIEW</li>
            <li>👉OSLOB</li>
            <li>👉WHALESHARK WATCHING</li>
            <li>👉SUMILON ISLAND WHITE SANDBAR</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉OSLOB CUARTEL</li>
            <li>👉OSLOB CHURCH</li>
            <li>👉SIMALA CHURCH</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉CARCAR PASALUBONG CENTER</li>
            <li>👉TABOAN MARKET</li>
            <li>👉HOTEL IN CEBU CITY</li>
          </ul>
          <span className='justify'>▶️ Duration 12hrs</span>
          <br></br>
        </div>
      </>
    )
  }
  const card2 = {
    title: '2 Days 1 Night Bohol',
    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling Gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 1 night</li>
            <li>▶️ 1 free lunch for Loboc River Cruise (buffet)</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE ( OPTIONAL )</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE ( OPTIONAL )</li>
            <li>👉MAN MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE </li>
            <li>👉TARSIER SANCTUARY </li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉HOTEL</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄2nd Day BOHOL</h2>
          <h4>💛 PANGLAO ISLAND HOPPING</h4>
          <ul>
            <li>👉BALICASAG ISLAND</li>
            <li>👉FISH SANCTUARY</li>
            <li>👉TURTLE SANCTUARY</li>
            <li>👉DOLPHIN WATCHING ( SEASONAL)</li>
            <li>👉VIRGIN ISLAND</li>
            <li>👉BACK TO HOTEL, AIRPORT OR SEAPORT</li>
          </ul>
        </div>
      </>
    )
  }
  const card3 = {
    title: '2 Days 1 Night Cebu & Bohol',
    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Ferry Boat (roundtrip Bohol or Cebu)</li>
            <li>▶️ Terminal fees</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 1 night</li>
            <li>▶️ 1 free lunch for Loboc River Cruise (buffet)</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day CEBU CITY TOUR </h2>

          <ul>
            <li>👉PICK UP 7:30AM</li>
            <li>👉SIRAO FLOWER GARDEN</li>
            <li>👉TEMPLE OF LEAH</li>
            <li>👉TAOIST TEMPLE</li>
            <li>👉COLON ST (THE OLDEST STREET IN THE PHILIPPINES)</li>
            <li>👉LUNCH AT RICO'S LECHON OR HOUSE OF LECHON (OPTIONAL)</li>
            <li>👉MAGELLAN'S CROSS</li>
            <li>👉STO. NIÑO CHURCH</li>
            <li>👉FORT SAN PEDRO</li>
            <li>👉FRENCH DE LA VIE</li>
            <li>👉CEBU HERITAGE MONUMENT</li>
            <li>👉YAP SAN DIEGO ANCESTRAL HOUSE</li>
            <li>👉10K ROSES</li>
            <li>👉DROP OFF TO HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 8hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE (OPTIONAL)</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE (OPTIONAL)</li>
            <li>👉MAN-MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE</li>
            <li>👉TARSIER SANCTUARY</li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉AIRPORT/SEAPORT, OR HOTEL</li>
          </ul>
          <div>
            <span> ✌️We have no hidden charges once the deal is closed.</span>
          </div>
          <br></br>
          <br></br>
        </div>
      </>
    )
  }
  const card4 = {
    title: '3 Days 2 Nights Cebu',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 2 nights</li>
            <li>▶️ 1 free lunch for canyoneering</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day MOALBOAL + KAWASAN FALLS</h2>
          <ul>
            <li>👉HOTEL PICK UP 4:00AM</li>
            <li>👉PESCADOR ISLAND</li>
            <li>👉SNORKELING AT FISH SANCTUARY</li>
            <li>👉SARDINES WATCHING</li>
            <li>👉TURTLE WATCHING</li>
            <li>👉CANYONEERING (OPTIONAL) WITH MEAL/LUNCH</li>
            <li>👉KAWASAN WATERFALLS</li>
            <li>👉SUNSET VIEW (LAMBOG)</li>
            <li>👉HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 10-12hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day OSLOB</h2>
          <ul>
            <li>👉PICK UP 4:30AM</li>
            <li>👉SUNRISE VIEW</li>
            <li>👉OSLOB</li>
            <li>👉WHALESHARK WATCHING</li>
            <li>👉SUMILON ISLAND WHITE SANDBAR</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉OSLOB CUARTEL</li>
            <li>👉OSLOB CHURCH</li>
            <li>👉SIMALA CHURCH</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉CARCAR PASALUBONG CENTER</li>
            <li>👉TABOAN MARKET</li>
            <li>👉HOTEL IN CEBU CITY</li>
          </ul>
          <span className='justify'>▶️ Duration 12hrs</span>
          <br></br>
          <br></br>
          <h2>🌄 🌄3rd Day CEBU CITY TOUR </h2>

          <ul>
            <li>👉PICK UP 7:30AM</li>
            <li>👉SIRAO FLOWER GARDEN</li>
            <li>👉TEMPLE OF LEAH</li>
            <li>👉TAOIST TEMPLE</li>
            <li>👉COLON ST (THE OLDEST STREET IN THE PHILIPPINES)</li>
            <li>👉LUNCH AT RICO'S LECHON OR HOUSE OF LECHON (OPTIONAL)</li>
            <li>👉MAGELLAN'S CROSS</li>
            <li>👉STO. NIÑO CHURCH</li>
            <li>👉FORT SAN PEDRO</li>
            <li>👉FRENCH DE LA VIE</li>
            <li>👉CEBU HERITAGE MONUMENT</li>
            <li>👉YAP SAN DIEGO ANCESTRAL HOUSE</li>
            <li>👉10K ROSES</li>
            <li>👉DROP OFF TO HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 8hrs</span>
          <br />
          <br />
        </div>
      </>
    )
  }
  const card5 = {
    title: '3 Days 2 Nights Bohol',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 2 nights</li>
            <li>▶️ 1 free lunch for Loboc River Cruise (buffet)</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE ( OPTIONAL )</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE ( OPTIONAL )</li>
            <li>👉MAN MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE </li>
            <li>👉TARSIER SANCTUARY </li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉 HOTEL</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄2nd Day BOHOL</h2>
          <h4>💛 PANGLAO ISLAND HOPPING</h4>
          <ul>
            <li>👉BALICASAG ISLAND</li>
            <li>👉FISH SANCTUARY</li>
            <li>👉TURTLE SANCTUARY</li>
            <li>👉DOLPHIN WATCHING ( SEASONAL)</li>
            <li>👉VIRGIN ISLAND</li>
            <li>👉BACK TO HOTEL, AIRPORT OR SEAPORT</li>
          </ul>
          <br></br>
          <br></br>
          <h2>🌄 🌄3rd Day BOHOL</h2>
          <h4>💛 PANGLAO LAND TOUR</h4>
          <ul>
            <li>👉SHELL MUSEUM</li>
            <li>👉FISH SANCTUARY</li>
            <li>👉TURTLE SANCTUARY</li>
            <li>👉DOLPHIN WATCHING ( SEASONAL)</li>
            <li>👉VIRGIN ISLAND</li>
            <li>👉BACK TO HOTEL, AIRPORT OR SEAPORT</li>
          </ul>
        </div>
      </>
    )
  }
  const card6 = {
    title: '3 Days 2 Nights Cebu & Bohol',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling Gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Ferry Boat (roundtrip Bohol or Cebu)</li>
            <li>▶️ Terminal fees</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 2 nights</li>
            <li>
              ▶️ 2 free lunches on Canyoneering and Loboc River Cruise (buffet)
            </li>
          </ul>
          <br />
          <h2>🌄 🌄1st Day MOALBOAL + KAWASAN FALLS</h2>
          <ul>
            <li>👉HOTEL PICK UP 4:00AM</li>
            <li>👉PESCADOR ISLAND</li>
            <li>👉SNORKELING AT FISH SANCTUARY</li>
            <li>👉SARDINES WATCHING</li>
            <li>👉TURTLE WATCHING</li>
            <li>👉CANYONEERING (OPTIONAL) WITH MEAL/LUNCH</li>
            <li>👉KAWASAN WATERFALLS</li>
            <li>👉SUNSET VIEW (LAMBOG)</li>
            <li>👉HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 10-12hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day OSLOB</h2>
          <ul>
            <li>👉PICK UP 4:30AM</li>
            <li>👉SUNRISE VIEW</li>
            <li>👉OSLOB</li>
            <li>👉WHALESHARK WATCHING</li>
            <li>👉SUMILON ISLAND WHITE SANDBAR</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉OSLOB CUARTEL</li>
            <li>👉OSLOB CHURCH</li>
            <li>👉SIMALA CHURCH</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉CARCAR PASALUBONG CENTER</li>
            <li>👉TABOAN MARKET</li>
            <li>👉HOTEL IN CEBU CITY</li>
          </ul>
          <span className='justify'>▶️ Duration 12hrs</span>
          <br />
          <br />
          <h2>🌄 🌄3rd Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE ( OPTIONAL )</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE ( OPTIONAL )</li>
            <li>👉MAN MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE </li>
            <li>👉TARSIER SANCTUARY </li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉HOTEL</li>
          </ul>
        </div>
      </>
    )
  }
  const card7 = {
    title: '4 Days 3 Nights Cebu',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 3 nights</li>
            <li>▶️ 1 free lunch for canyoneering</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day MOALBOAL + KAWASAN FALLS</h2>
          <ul>
            <li>👉HOTEL PICK UP 4:00AM</li>
            <li>👉PESCADOR ISLAND</li>
            <li>👉SNORKELING AT FISH SANCTUARY</li>
            <li>👉SARDINES WATCHING</li>
            <li>👉TURTLE WATCHING</li>
            <li>👉CANYONEERING (OPTIONAL) WITH MEAL/LUNCH</li>
            <li>👉KAWASAN WATERFALLS</li>
            <li>👉SUNSET VIEW (LAMBOG)</li>
            <li>👉HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 10-12hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day OSLOB</h2>
          <ul>
            <li>👉PICK UP 4:30AM</li>
            <li>👉SUNRISE VIEW</li>
            <li>👉OSLOB WHALESHARK WATCHING</li>
            <li>👉SUMILON ISLAND WHITE SANDBAR</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>
              👉BRUMINI RESORT OSLOB AFTER LUNCH CHECK IN AND ENJOY (WITH
              BREAKFAST)
            </li>
          </ul>
          <span className='justify'>▶️ Duration 12hrs</span>
          <br></br>
          <br></br>
          <h2>🌄 🌄3rd Day (Free time in the Morning with Resort)</h2>
          <ul>
            <li>👉OSLOB CUARTEL</li>
            <li>👉OSLOB CHURCH</li>
            <li>👉SIMALA SHRINE</li>
            <li>👉CARCAR PASALUBONG CENTER</li>
            <li>👉TABOAN MARKET</li>
            <li>👉HOTEL IN THE CITY</li>
          </ul>
          <span className='justify'>▶️ Duration around 12hrs</span>
          <br></br>
          <br></br>
          <h2>🌄 🌄4th Day CEBU CITY TOUR </h2>

          <ul>
            <li>👉PICK UP 7:30AM</li>
            <li>👉SIRAO FLOWER GARDEN</li>
            <li>👉TEMPLE OF LEAH</li>
            <li>👉TAOIST TEMPLE</li>
            <li>👉COLON ST (THE OLDEST STREET IN THE PHILIPPINES)</li>
            <li>👉LUNCH AT RICO'S LECHON OR HOUSE OF LECHON (OPTIONAL)</li>
            <li>👉MAGELLAN'S CROSS</li>
            <li>👉STO. NIÑO CHURCH</li>
            <li>👉FORT SAN PEDRO</li>
            <li>👉FRENCH DE LA VIE</li>
            <li>👉CEBU HERITAGE MONUMENT</li>
            <li>👉YAP SAN DIEGO ANCESTRAL HOUSE</li>
            <li>👉10K ROSES</li>
            <li>👉DROP OFF TO HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 8hrs</span>
          <br />
          <br />
        </div>
      </>
    )
  }
  const card8 = {
    title: '4 Days 3 Nights Bohol',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 3 nights</li>
            <li>▶️ 1 free lunch for Loboc River Cruise (buffet)</li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE ( OPTIONAL )</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE ( OPTIONAL )</li>
            <li>👉MAN MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE </li>
            <li>👉TARSIER SANCTUARY </li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉 HOTEL</li>
          </ul>
          <br />
          <br />

          <h2>🌄 🌄2nd Day BOHOL</h2>
          <h4>💛 PANGLAO LAND TOUR</h4>
          <ul>
            <li>👉SHELL MUSEUM</li>
            <li>👉FISH SANCTUARY</li>
            <li>👉TURTLE SANCTUARY</li>
            <li>👉DOLPHIN WATCHING ( SEASONAL)</li>
            <li>👉VIRGIN ISLAND</li>
            <li>👉BACK TO HOTEL, AIRPORT OR SEAPORT</li>
          </ul>
          <h2>🌄 🌄3rd Day BOHOL</h2>
          <h4>💛 PANGLAO ISLAND HOPPING</h4>
          <ul>
            <li>👉BALICASAG ISLAND</li>
            <li>👉FISH SANCTUARY</li>
            <li>👉TURTLE SANCTUARY</li>
            <li>👉DOLPHIN WATCHING ( SEASONAL)</li>
            <li>👉VIRGIN ISLAND</li>
            <li>👉BACK TO HOTEL, AIRPORT OR SEAPORT</li>
          </ul>
          <br></br>
          <br></br>
          <h2>🌄 🌄4th Day BOHOL </h2>
          <h4>💛 ANDA TOUR</h4>
          <ul>
            <li>👉ANDA WHITE SAND BEACH</li>
            <li>👉CABAGNOW CAVE POOL</li>
            <li>👉CAN UMANTAD FALLS</li>
            <li>👉CANDIJAY MINI RICE TERRACES</li>
            <li>👉CANAWA SPRINGS</li>
            <li>👉BACK TO HOTEL</li>
          </ul>
          <br></br>
          <br></br>
        </div>
      </>
    )
  }
  const card9 = {
    title: '4 Days 3 Nights Cebu & Bohol',

    details: (
      <>
        <h1>{header}</h1>
        <div>
          <h2>💥INCLUSIVES</h2>
          <ul>
            <li>▶️ Van/Car ( Airconditioned and Professional Driver)</li>
            <li>▶️ Gas</li>
            <li>▶️ Snorkeling gear</li>
            <li>▶️ Life jacket</li>
            <li>▶️ Boat</li>
            <li>▶️ Municipal fees</li>
            <li>▶️ Entrance fees</li>
            <li>▶️ Guiding fees</li>
            <li>▶️ Parking fees</li>
            <li>▶️ Environmental fees</li>
            <li>▶️ Hotel accommodations with breakfast 3 nights</li>
            <li>
              ▶️ 2 free lunches on Canyoneering and Loboc River Cruise (buffet)
            </li>
          </ul>
          <br />
          <br />
          <h2>🌄 🌄1st Day CEBU CITY TOUR </h2>

          <ul>
            <li>👉PICK UP 7:30AM</li>
            <li>👉SIRAO FLOWER GARDEN</li>
            <li>👉TEMPLE OF LEAH</li>
            <li>👉TAOIST TEMPLE</li>
            <li>👉COLON ST (THE OLDEST STREET IN THE PHILIPPINES)</li>
            <li>👉LUNCH AT RICO'S LECHON OR HOUSE OF LECHON (OPTIONAL)</li>
            <li>👉MAGELLAN'S CROSS</li>
            <li>👉STO. NIÑO CHURCH</li>
            <li>👉FORT SAN PEDRO</li>
            <li>👉FRENCH DE LA VIE</li>
            <li>👉CEBU HERITAGE MONUMENT</li>
            <li>👉YAP SAN DIEGO ANCESTRAL HOUSE</li>
            <li>👉10K ROSES</li>
            <li>👉DROP OFF TO HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 8hrs</span>
          <br />
          <br />
          <h2>🌄 🌄2nd Day MOALBOAL + KAWASAN FALLS</h2>
          <ul>
            <li>👉HOTEL PICK UP 4:00AM</li>
            <li>👉PESCADOR ISLAND</li>
            <li>👉SNORKELING AT FISH SANCTUARY</li>
            <li>👉SARDINES WATCHING</li>
            <li>👉TURTLE WATCHING</li>
            <li>👉CANYONEERING (OPTIONAL) WITH MEAL/LUNCH</li>
            <li>👉KAWASAN WATERFALLS</li>
            <li>👉SUNSET VIEW (LAMBOG)</li>
            <li>👉HOTEL</li>
          </ul>
          <span className='justify'>▶️ Duration 10-12hrs</span>
          <br></br>
          <br></br>
          <h2>🌄 🌄3rd Day OSLOB</h2>
          <ul>
            <li>👉PICK UP 4:30AM</li>
            <li>👉SUNRISE VIEW</li>
            <li>👉OSLOB</li>
            <li>👉WHALESHARK WATCHING</li>
            <li>👉SUMILON ISLAND WHITE SANDBAR</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉OSLOB CUARTEL</li>
            <li>👉OSLOB CHURCH</li>
            <li>👉SIMALA CHURCH</li>
            <li>👉TUMALOG WATERFALLS</li>
            <li>👉CARCAR PASALUBONG CENTER</li>
            <li>👉TABOAN MARKET</li>
            <li>👉HOTEL IN CEBU CITY</li>
          </ul>
          <span className='justify'>▶️ Duration 12hrs</span>
          <br></br>
          <br></br>
          <h2>🌄 🌄4th Day BOHOL</h2>
          <h4>💛 COUNTRY SIDE TOUR</h4>
          <ul>
            <li>👉BLODD COMPACT MONUMENT</li>
            <li>👉ATV RIDE ( OPTIONAL )</li>
            <li>👉CHOCOLATE HILLS</li>
            <li>👉ZIPLINE ( OPTIONAL )</li>
            <li>👉MAN MADE FOREST</li>
            <li>👉HANGING BRIDGE</li>
            <li>👉LOBOC RIVER CRUISE </li>
            <li>👉TARSIER SANCTUARY </li>
            <li>👉BUTTERFLY SANCTUARY</li>
            <li>👉BACLAYON CHURCH</li>
            <li>👉BACLAYON MUSEUM</li>
            <li>👉PYTHON</li>
            <li>👉SOUVENIR SHOP</li>
            <li>👉 HOTEL</li>
          </ul>
          <br />
          <br />
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Cebu Bohol Package Tours</title>
        <meta
          name='Package Tours'
          content='Let us take care of all your travel planning. Relax in the heart of Cebu, or in Beautiful rural Bohol while experiencing all the best that the Visayas has to offer!'
        />
      </Helmet>
      <Row
        className='g-4 setOfCards'
        xs={1}
        sm={1}
        md={2}
        lg={2}
      >
        <Col>
          <DayTourCard props={card1}></DayTourCard>
          <DayTourCard props={card2}></DayTourCard>
          <DayTourCard props={card3}></DayTourCard>
          <DayTourCard props={card4}></DayTourCard>
          <DayTourCard props={card5}></DayTourCard>
        </Col>
        <Col>
          <DayTourCard props={card6}></DayTourCard>
          <DayTourCard props={card7}></DayTourCard>
          <DayTourCard props={card8}></DayTourCard>
          <DayTourCard props={card9}></DayTourCard>
        </Col>
      </Row>

      <h2 className='justify'>
        Don't see something that fits exactly what you're looking for? Check out
        our customizable tours page!
      </h2>
    </React.Fragment>
  )
}

export default PackageTours
