import React from "react";
import Card from "react-bootstrap/Card";

const MainCard = ({ props }) => {
   return (
      <Card className="mainCard">
         <Card.Img
            className="cardImageMain"
            variant="top"
            src={props.image}
         />
         <Card.Body>
            <Card.Title>
               <b>{props.title}</b>
            </Card.Title>
            <Card.Text className="large">{props.content}</Card.Text>
         </Card.Body>
      </Card>
   );
};

export default React.memo(MainCard);
