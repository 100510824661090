import React from 'react'
import HomeCarousel from './Carousel'
import HomeCards from './HomeCards'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Cebu Bohol Tours Home</title>
        <meta
          name='Home'
          content='Check out the unique Chocolate Hills, Beautiful beaches, historical treasures of Cebu and Bohol!'
        />
      </Helmet>
      <main role='main'>
        <HomeCarousel></HomeCarousel>
        <div className='container'>
          <HomeCards></HomeCards>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Home
