import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Form, Input } from 'semantic-ui-react'
import { Button } from 'react-bootstrap'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import './contact.css'
import { Helmet } from 'react-helmet'

const CustomizedTours = () => {
  const SERVICE_ID = 'service_oivhbaf'
  const TEMPLATE_ID = 'template_zqlvygx'
  const USER_ID = 'I6drwCwYHB5A3yHTa'

  const handleOnSubmit = (e) => {
    e.preventDefault()
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text)
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      },
      (error) => {
        console.log(error.text)
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text
        })
      }
    )
    e.target.reset()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Cebu Bohol Custom Tours</title>
        <meta
          name='Custom Tours'
          content='We are experts of the travel industry in Cebu and Bohol. Any custom requests we are more than happy to accomodate.'
        />
      </Helmet>
      <main role='main'>
        <div className='container'>
          <h4>
            We are happy to accomodate special requests! Don't see a particular
            day or full package tour you like? Please use this form and
            reference the types of tours and the specific pieces you are
            interested in. At Cebu Bohol Tours, we enjoy mixing and matching
            your favorite adventures from various tours, and are excited to see
            your dream vacation come to fruition.
          </h4>
          <Row
            xs={1}
            sm={1}
            md={2}
            lg={2}
            className='g-4 twoframes'
          >
            <Col>
              <ul className='h4'>
                <br></br>
                <h1>
                  <u>
                    <b>Tour Listings</b>
                  </u>
                </h1>
                <br></br>
                <li>Cebu City Tour</li>
                <br />
                <li>Oslob Tour</li>
                <br />
                <li>Kawasan Moalboal Tour</li>
                <br />
                <li>Cebu Uphill Tour</li>
                <br />
                <li>Bohol Countryside Tour</li>
                <br />
                <li>Bohol Panglao Land Tour</li>
                <br />
                <li>Bohol Pangload Island Hopping Tour</li>
              </ul>
              <br></br>
              <h4>
                You will find the details of each tour listing in the "Day
                Tours" Page in the Navbar, including pictures in the details
                section.
              </h4>
            </Col>
            <Col>
              {' '}
              <div className='container'>
                <section
                  id='content'
                  style={{ float: 'center', width: '100%' }}
                >
                  <Form onSubmit={handleOnSubmit}>
                    <h1>Your Idea</h1>
                    <Form.Field
                      control={Input}
                      name='user_name'
                      placeholder='Name…'
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_email'
                      placeholder='Email…'
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_phone'
                      placeholder='Phone Number…'
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_group'
                      placeholder='Group Size…'
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_pickup'
                      placeholder='Pickup Location…'
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_dropoff'
                      placeholder='Dropoff Location…'
                      required
                    />

                    <Form.Field
                      as='textarea'
                      rows='8'
                      name='user_message'
                      placeholder='Number of Days and Activites... ex:
                            1st Day... Bohol
                            2nd Day... Cebu
                           '
                      required
                    />
                    <Form.Field
                      control={Input}
                      name='user_package'
                      placeholder='Accomodations?'
                      required
                    ></Form.Field>
                    <Button
                      type='submit'
                      className='btn btn-success'
                    >
                      Submit
                    </Button>
                  </Form>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </React.Fragment>
  )
}

export default CustomizedTours
