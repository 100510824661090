import React from "react";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
   const date = new Date().getFullYear();

   return (
      <React.Fragment>
         <footer className="container">
            <br />
            <p>
               <strong>cebuboholtourpackages@gmail.com</strong>
            </p>
            <p>
               <a
                  className="large"
                  href="https://www.facebook.com/baratas143/">
                  <FaFacebook />
               </a>
               <strong>+63 (963) 270 2406</strong>
            </p>
            <p>
               &copy; <i>Faithful Audio Development</i> {date}
            </p>
         </footer>
      </React.Fragment>
   );
};

export default Footer;
