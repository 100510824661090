import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "./contact.css";
import * as Yup from "yup";

const validationEmailSchema = Yup.object().shape({
   user_name: Yup.string().min(1).max(100).required("Please fill in your Name"),
   user_email: Yup.string().min(1).max(100).required("Please provide an email"),
   user_phone: Yup.string().min(1).max(100).required("Please provide a cell #"),
   user_group: Yup.string().min(1).max(100).required("Please provide a group size"),
   user_pickup: Yup.string()
      .min(1)
      .max(100)
      .required("Please provide a pickup location"),
   user_dropoff: Yup.string()
      .min(1)
      .max(100)
      .required("Please provide a dropoff location"),
   user_message: Yup.string()
      .min(1)
      .max(5000)
      .required("Please tell us about your trip"),
   user_package: Yup.string().min(1).max(100).required("Please choose a package"),
});

const DetailedForm = (props) => {
   const templateParams = {
      user_name: "",
      user_email: "",
      user_phone: "",
      user_group: "",
      user_pickup: "",
      user_dropoff: "",
      user_message: "",
      user_package: "",
   };

   console.log(props);
   const SERVICE_ID = "service_oivhbaf";
   const TEMPLATE_ID = "template_zqlvygx";
   const USER_ID = "I6drwCwYHB5A3yHTa";

   const getFormData = (values) => {
      values.tour_title = props.title;
      console.log(values);

      emailjs.send(SERVICE_ID, TEMPLATE_ID, values, USER_ID).then(
         function (response) {
            console.log("SUCCESS!", response.status, response.text);
            Swal.fire({
               icon: "success",
               title: "Message Sent Successfully",
            });
         },
         function (error) {
            console.log("FAILED...", error);
            Swal.fire({
               icon: "error",
               title: "Ooops, something went wrong",
               text: error,
            });
         }
      );
   };

   return (
      <div className="container">
         <section
            id="content"
            style={{ float: "center", width: "100%" }}>
            <Formik
               enableReinitialize={true}
               initialValues={templateParams}
               onSubmit={getFormData}
               validationSchema={validationEmailSchema}>
               <Form>
                  <h1>Quote Inquiry</h1>
                  <div>
                     <Field
                        className="field"
                        type="text"
                        name="tour_title"
                        value={props.title}
                        disabled></Field>
                     <ErrorMessage
                        name="tour_title"
                        component="div"
                        className="has-error"
                        id="msgTitle"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_name"
                        placeholder="Name"></Field>
                     <ErrorMessage
                        name="user_name"
                        component="div"
                        className="has-error"
                        id="msgName"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_email"
                        placeholder="Email"></Field>
                     <ErrorMessage
                        name="user_email"
                        component="div"
                        className="has-error"
                        id="msgEmail"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_phone"
                        placeholder="Phone Number"></Field>
                     <ErrorMessage
                        name="user_phone"
                        component="div"
                        className="has-error"
                        id="msgPhone"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_group"
                        placeholder="Group Size"></Field>
                     <ErrorMessage
                        name="user_group"
                        component="div"
                        className="has-error"
                        id="msgGroup"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_pickup"
                        placeholder="Pickup Location"></Field>
                     <ErrorMessage
                        name="user_pickup"
                        component="div"
                        className="has-error"
                        id="msgPickup"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_dropoff"
                        placeholder="Dropoff Location"></Field>
                     <ErrorMessage
                        name="user_dropoff"
                        component="div"
                        className="has-error"
                        id="msgDropoff"
                     />
                  </div>
                  <div className="form-group">
                     <Field
                        className="field"
                        type="text"
                        name="user_package"
                        placeholder="Full Service or Car/Van only?"></Field>
                     <ErrorMessage
                        name="user_package"
                        component="div"
                        className="has-error"
                        id="msgPackage"
                     />
                  </div>

                  <div className="form-group">
                     <Field
                        as="textarea"
                        rows="8"
                        className="field"
                        type="text"
                        name="user_message"
                        placeholder="Details of your trip"></Field>
                     <ErrorMessage
                        name="user_message"
                        component="div"
                        className="has-error"
                        id="msgMessage"
                     />
                  </div>
                  <button
                     type="submit"
                     className="btn btn-primary">
                     Submit
                  </button>
                  <br />
               </Form>
            </Formik>
         </section>
      </div>
   );
};

export default DetailedForm;
