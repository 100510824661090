import React from 'react'
import './App.css'
import Navbar from './components/Navbar'
import Home from './components/Home'
import Footer from './components/Footer'
import Contact from './components/Contact'
import DayTour from './components/DayTour'
import CustomizedTour from './components/CustomizedTours'
import PackageTour from './components/PackageTours'
import { Routes, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function App() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Cebu Bohol Tours</title>
        <meta
          name='Main Page'
          content='Beautiful tropical island paradise activities await you, check out some of the activities we provide!'
        />
      </Helmet>
      <Navbar></Navbar>
      <Routes>
        <Route
          path='/'
          element={<Home />}
        ></Route>
        <Route
          path='/contact'
          element={<Contact />}
        ></Route>
        <Route
          path='/daytours'
          element={<DayTour />}
        ></Route>
        <Route
          path='/customizedtours'
          element={<CustomizedTour />}
        ></Route>
        <Route
          path='/packagetours'
          element={<PackageTour />}
        ></Route>
      </Routes>

      <Footer></Footer>
    </React.Fragment>
  )
}

export default App
